<template>
    <div class="commentList boxS">
        <div class="commentItem paddingTB-20" v-for="(item, index) of commentList" :key="index">
            <div class="f-c-c">
                <el-image class="wh-60 borderRa-50 mr-20 alignSelf-start" :src="item.userAvatarUrl"></el-image>
                <div class="f-cl f1 info">
                    <div class="f-c-b">
                        <div class=" f-c">{{ item.mcommentUsername }}
                            <span v-if="item.connectMcommentUsername" class="triangle"></span>
                            <span>{{ item.connectMcommentUsername }}</span>
                        </div>
                        <!-- <el-popover placement="bottom-start" trigger="click"
                            v-if="item.fcommentUserId == userId || item.mcommentUserId == userId">
                            <el-button @click="removeItem(item)">删除</el-button>
                            <svg slot="reference" width="16" height="16" viewBox="0 0 24 24"
                                class="ZDI ZDI--Dots24 css-zgte1c" fill="currentColor">
                                <path
                                    d="M5.34 12a1.665 1.665 0 1 1-3.33 0 1.665 1.665 0 0 1 3.33 0ZM13.665 12a1.665 1.665 0 1 1-3.33 0 1.665 1.665 0 0 1 3.33 0ZM21.99 12a1.665 1.665 0 1 1-3.33 0 1.665 1.665 0 0 1 3.33 0Z">
                                </path>
                            </svg>
                        </el-popover> -->
                    </div>
                    <div class="f-c-b">
                        <div class="mt-20 col-66 lh-25" style="width: 80%;">{{ item.mcommentConent }}</div>
                        <div style="color: #0363FA;" class="hover-pointer" @click="showInput(index)">回复</div>
                    </div>
                </div>
            </div>
            <div class="f-c-c" v-show="item.showCommonInput">
                <div class="vueEmoji">
                    <textarea class="textarea" v-model="thirdLevelcommentValue" placeholder="善语结善缘，恶语伤人心~"></textarea>
                    <img class="commentBtn" @click="published(item)"
                        src="@/assets/Community/IndustryTrends/commentBtn.png" alt="">
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'SecondaryComment',
    inject: ['reload'],
    // components: {
    //     CommentItem: () => import("./CommentItem.vue")
    // },
    data() {
        return {
            userId: this.$getStorage('userId'),
            commentList: [],
            thirdLevelcommentValue: '',



            secondaryCommentList: [],//二级评论列表
            secondaryCommentTotal: 0,//二级评论条数
            loading: false,
            noMore: false,
            page: 1,
            size: 10,
            currentPage: 0,
            totalPages: 0,
            isDeleteSecondComment: false,//是否触发删除二级评论
        }
    },
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        getListInfo: {},
        // 类型
        queryType: {}

    },
    computed: {
        disabled() {
            return this.loading
        }
    },
    watch: {
        list() {
            this.commentList = this.list
        }
    },
    methods: {
        // 显示评论框
        showInput(idx) {
            this.commentList.forEach((item, index) => {
                if (index != idx) {
                    item.showCommonInput = false
                } else {
                    item.showCommonInput = true
                    window.addEventListener("scroll", this.scrolling);
                }
            })
        },

        scrolling() {
            setTimeout(() => {
                this.commentList.forEach((item) => {
                    item.showCommonInput = false
                })
            }, 1000);
        },



        // 发表评论
        published(item) {
            switch (this.queryType) {
                case 'ProductExchange':
                    this.addForumPostSecondaryComment(item);
                    break;
                case 'IndustryTrends':
                    this.addForumNewsPostSecondaryComment(item)
                    break;
                case 'ShiLiaoZaTan':
                this.addForumChatPostSecondaryComment(item);
                    break;    
            }
        },
        // 行业动态---二级评论发表
        addForumNewsPostSecondaryComment(item) {
            let that = this;
            if (that.thirdLevelcommentValue) {
                let data = {
                    mcommentUserId: parseInt(that.$store.state.userId),
                    postId: item.postId,
                    connectFcommentId: item.connectFcommentId,
                    connectMcommentUserId: item.mcommentUserId,
                    connectMcommentUsername: item.mcommentUsername,
                    mcommentConent: that.thirdLevelcommentValue,
                    mobject: "",
                    mcommentLocation: that.$getStorage('getIpAddress').fcommentLocation,
                    sourceIp: that.$getStorage('getIpAddress').sourceIp,
                };
                that.$http.addForumNewsPostSecondaryComment(data).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success');
                        that.$emit('getDateafter', that.getListInfo);
                        that.thirdLevelcommentValue = '';
                    } else {
                        that.$common.message(res.msg, 'error')
                    }
                })
            } else {
                that.$common.message('请输入评论！', 'error')
            }
        },
        // 产品供应---二级评论发表
        addForumPostSecondaryComment(item){
            console.log(item);
            let that = this;
            if (that.thirdLevelcommentValue) {
                let data = {
                    mcommentUserId: parseInt(that.$store.state.userId),
                    postId: item.postId,
                    connectFcommentId: item.connectFcommentId,
                    connectMcommentUserId: item.mcommentUserId,
                    connectMcommentUsername: item.mcommentUsername,
                    mcommentConent: that.thirdLevelcommentValue,
                    mobject: "",
                    mcommentLocation: that.$getStorage('getIpAddress').fcommentLocation,
                    sourceIp: that.$getStorage('getIpAddress').sourceIp,
                };
                that.$http.addForumPostSecondaryComment(data).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success');
                        that.$emit('getDateafter', that.getListInfo);
                        that.thirdLevelcommentValue = '';
                    } else {
                        that.$common.message(res.msg, 'error')
                    }
                })
            } else {
                that.$common.message('请输入评论！', 'error')
            }
        },
        // 识料杂谈---二级评论发表
        addForumChatPostSecondaryComment(item){
            let that = this;
            if (that.thirdLevelcommentValue) {
                let data = {
                    mcommentUserId: parseInt(that.$store.state.userId),
                    postId: item.postId,
                    connectFcommentId: item.connectFcommentId,
                    connectMcommentUserId: item.mcommentUserId,
                    connectMcommentUsername: item.mcommentUsername,
                    mcommentConent: that.thirdLevelcommentValue,
                    mobject: "",
                    mcommentLocation: that.$getStorage('getIpAddress').fcommentLocation,
                    sourceIp: that.$getStorage('getIpAddress').sourceIp,
                };
                that.$http.addForumChatPostSecondaryComment(data).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success');
                        that.$emit('getDateafter', that.getListInfo);
                        that.thirdLevelcommentValue = '';
                    } else {
                        that.$common.message(res.msg, 'error')
                    }
                })
            } else {
                that.$common.message('请输入评论！', 'error')
            }

        }
    }
}
</script>

<style lang='less' scoped>
.commentList {
    padding: 20px;
    // border: 1px solid red;
    max-height: 600px;
    // height: 600px;
    overflow-y: auto;

    .commentItem {
        border-top: 1px solid #F7F7F7;
        padding: 30px 0;

        .info {
            min-height: 80px;
            font-size: 16px;
        }

        .vueEmoji {
            margin-top: 10px;
            position: relative;

            .textarea {
                width: 800px;
                height: 80px;
                resize: none;
                font-size: 18px;
                padding: 30px 20px 50px 30px;
                border-radius: 10px;
                border: 1px solid #dfdfdf;
            }

            .commentBtn {
                // position: relative;
                position: absolute;
                right: 40px;
                bottom: 20px;
                cursor: pointer;

            }
        }
    }
}
</style>

import { render, staticRenderFns } from "./SecondaryComment.vue?vue&type=template&id=4ee3e452&scoped=true&"
import script from "./SecondaryComment.vue?vue&type=script&lang=js&"
export * from "./SecondaryComment.vue?vue&type=script&lang=js&"
import style0 from "./SecondaryComment.vue?vue&type=style&index=0&id=4ee3e452&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ee3e452",
  null
  
)

export default component.exports